<template>
  <v-app>
    <div class="row">
      <div class="col-md-4">
        <div
          class="
            d-flex
            justify-content-center
            align-items-center
            image-container
          "
        >
          <div style="height: 350px; width: 350px; position: relative">
            <img
              class="image"
              :src="[uploadedImageUrl == '' ? form.photo : uploadedImageUrl]"
              @load="onImageLoad()"
            />
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <b-form
          @submit.stop.prevent="formOnsubmit"
          enctype="multipart/form-data"
        >
          <b-form-group id="input-group-inventory-code">
            <label for="input-inventory-code"
              >Kode Inventori: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-inventory-code"
              v-model="form.inventory_code"
              placeholder="Kode Inventori"
            ></b-form-input>
            <small class="text-danger">{{ error.inventory_code }}</small>
          </b-form-group>

          <!-- Input Name -->
          <b-form-group
            id="input-group-name"
            label="Nama Inventori:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Inventori"
            >
            </b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <!-- Image -->
          <b-form-group id="input-group-image">
            <label for="input-image"
              >Gambar: <em class="text-muted">opsional</em></label
            >
            <b-form-file
              v-model="form.photo"
              placeholder="Upload Gambar"
              drop-placeholder="Drop file here..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.photo }}</small>
          </b-form-group>

          <!-- Input Category -->
          <b-form-group
            id="input-group-inventory-category"
            label="Kategori Inventori:"
            label-for="input-inventory-category"
          >
            <treeselect
              v-model="form.inventory_category_id"
              :multiple="false"
              :options="inventory_categories"
            />
            <small class="text-danger">{{ error.inventory_category_id }}</small>
          </b-form-group>

          <!-- Input Category -->
          <b-form-group
            id="input-group-uom"
            label="Satuan:"
            label-for="input-uom"
          >
            <treeselect
              v-model="form.uom_id"
              :multiple="false"
              :options="uoms"
              placeholder="Pilih Satuan"
            />
            <small class="text-danger">{{ error.uom_id }}</small>
          </b-form-group>

          <!-- Input Type -->
          <b-form-group
            id="input-group-inventory-type"
            label="Tipe Inventori:"
            label-for="input-inventory-type"
          >
            <treeselect
              v-model="form.inventory_type_id"
              :multiple="false"
              :options="inventory_types"
            />
            <small class="text-danger">{{ error.inventory_type_id }}</small>
          </b-form-group>

          <!-- Input status -->
          <b-form-group
            id="input-group-inventory-status"
            label="Status Inventori:"
            label-for="input-inventory-status"
          >
            <treeselect
              v-model="form.inventory_status_id"
              :multiple="false"
              :options="inventory_statuses"
            />
            <small class="text-danger">{{ error.inventory_status_id }}</small>
          </b-form-group>

          <!-- Input Merek -->
          <b-form-group id="input-group-brand">
            <label for="input-brand"
              >Merek: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-brand"
              v-model="form.brand"
              placeholder="Merek"
            >
            </b-form-input>
            <small class="text-danger">{{ error.brand }}</small>
          </b-form-group>

          <!-- Input Serial Number -->
          <b-form-group id="input-group-serial_number">
            <label for="input-serial_number"
              >Serial Number: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-serial_number"
              v-model="form.serial_number"
              placeholder="Serial Number"
            >
            </b-form-input>
            <small class="text-danger">{{ error.serial_number }}</small>
          </b-form-group>

          <!-- Input Produk Number -->
          <b-form-group id="input-group-product_number">
            <label for="input-product_number"
              >Produk Number: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-product_number"
              v-model="form.product_number"
              placeholder="Produk Number"
            >
            </b-form-input>
            <small class="text-danger">{{ error.product_number }}</small>
          </b-form-group>

          <b-form-group id="input-group-buy-date">
            <label for="input-buy-date"
              >Tanggal Beli: <em class="text-muted">opsional</em></label
            >
            <v-menu
              v-model="menuDate1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-date"
                  v-model="form.buy_date"
                  label="Tanggal Beli:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.buy_date"
                @input="menuDate1 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.buy_date }}</small>
          </b-form-group>

          <b-form-group id="input-group-inventory-date">
            <label for="input-inventory-date"
              >Tanggal Tercatat Inventaris:
              <em class="text-muted">opsional</em></label
            >
            <v-menu
              v-model="menuDate2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-date"
                  v-model="form.inventory_date"
                  label="Tanggal Tercatat Inventaris:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.inventory_date"
                @input="menuDate2 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.inventory_date }}</small>
          </b-form-group>

          <!-- Input Description -->
          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="6"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>

          <!-- Button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="default"
            @click="$router.push('/inventory/list')"
          >
            Batal
          </b-button>
        </b-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Add",

  props: {
    form: Object,
    route: String,
  },

  components: {
    Card,
  },

  data() {
    return {
      inventory_categories: [],
      inventory_types: [],
      inventory_statuses: [],
      uoms: [],
      // vendors: [],
      // Error
      error: {
        name: "",
        photo: "",
        uom_id: "",
        inventory_category_id: "",
        inventory_type_id: "",
        inventory_status_id: "",
        serial_number: "",
        product_number: "",
        brand: "",
        buy_date: "",
        inventory_date: "",
      },
      imgLoaded: false,
      uploadedImageUrl: "",
      menuDate1: false,
      menuDate2: false,
    };
  },

  computed: {
    formatedSalesPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.sales_price = newValue.toLocaleString("id-ID");
        } else {
          this.form.sales_price = "";
        }
      },
      get() {
        return this.form.sales_price;
      },
    },
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      console.log(evt);
      this.form.photo = evt.target.files[0];
      console.log("photo", this.form.photo);
    },

    async getInventoryCategoryOption() {
      let response = await module.setTreeSelect("inventory-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.inventory_categories = response.data;
        this.inventory_categories.unshift({
          label: "Pilih Kategori Inventori",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getInventoryTypeOption() {
      let response = await module.setTreeSelect("inventory-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.inventory_types = response.data;
        this.inventory_types.unshift({
          label: "Pilih Tipe Inventori",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getInventoryStatusOption() {
      let response = await module.setTreeSelect("inventory-statuses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.inventory_statuses = response.data;
        this.inventory_statuses.unshift({
          label: "Pilih Status Inventori",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getUomOption() {
      let response = await module.setTreeSelect("uoms");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.uoms = response.data;
        this.uoms.unshift({
          label: "Pilih Satuan",
          id: "",
          isDisabled: true,
        });
      }
    },
    // async getVendorOption() {
    //   let response = await module.setTreeSelect("vendors");
    //   // Success
    //   if (response.state == "success") {
    //     // Set Option Select
    //     this.vendors = response.data;
    //   }
    // },

    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === "is_ecommerce_product") {
          formData.append(key, value ? 1 : 0);
        } else {
          formData.append(key, value);
        }
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/inventory/list");
      }
    },
  },

  mounted() {
    this.getInventoryCategoryOption();
    this.getInventoryTypeOption();
    this.getInventoryStatusOption();
    this.getUomOption();
    // this.getVendorOption();
  },
};
</script>

<style>
</style>